import React from "react";
import styled from "@emotion/styled";

const Icon = styled.svg`
  width: ${props => props.width ? props.width : '100%' };
  height: auto;
    .cls-1 {
        fill: ${props => props.fill ? props.fill : '#fff'};
      }
`;

const MeetingInfo = (props) => {
  return (
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.00275 50" {...props}>
      <g id="Group_11" data-name="Group 11">
        <path id="Path_36" data-name="Path 36" class="cls-1" d="M38.57337,14.063H9.94237A7.527,7.527,0,0,1,15.54438,8a4.1,4.1,0,0,0,3.029,1.372h5.714a4.5,4.5,0,0,0,4.285-4.687A4.5,4.5,0,0,0,24.28737,0h-5.714a4.359,4.359,0,0,0-4.114,3.442,12.128,12.128,0,0,0-8.8,10.622h-4.229a1.5,1.5,0,0,0-1.429,1.561v1.563a1.5,1.5,0,0,0,1.429,1.562h37.143a1.5,1.5,0,0,0,1.428-1.562V15.625a1.5,1.5,0,0,0-1.428-1.562m-2.857,31.25h-3.415l1.984-23.438h-28.57l1.986,23.438h-3.414a1.5,1.5,0,0,0-1.429,1.562v1.563A1.5,1.5,0,0,0,4.28737,50h31.429a1.5,1.5,0,0,0,1.426-1.562V46.875a1.5,1.5,0,0,0-1.426-1.562"/>
      </g>
</Icon>

  )
}

export default MeetingInfo;
