import React from "react";
import styled from "@emotion/styled";

const Icon = styled.svg`
  width: ${props => props.width ? props.width : '100%' };
  height: auto;
    .cls-1 {
        fill: ${props => props.fill ? props.fill : '#fff'};
      }
`;

const OnlinePayments = (props) => {
  return (
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.286 50" {...props}>
       <g id="Group_30" data-name="Group 30">
          <path id="Path_39" data-name="Path 39" class="cls-1" d="M0,44.643A5.358,5.358,0,0,0,5.357,50H58.929a5.358,5.358,0,0,0,5.357-5.357V25H0Zm21.429-7.588a1.343,1.343,0,0,1,1.339-1.339H37.946a1.343,1.343,0,0,1,1.339,1.339v4.464a1.343,1.343,0,0,1-1.339,1.339H22.768a1.343,1.343,0,0,1-1.339-1.339Zm-14.287,0a1.344,1.344,0,0,1,1.339-1.339h8.036a1.343,1.343,0,0,1,1.339,1.339v4.464a1.343,1.343,0,0,1-1.339,1.339H8.482a1.344,1.344,0,0,1-1.339-1.339Zm57.144-31.7v5.357H0V5.357A5.359,5.359,0,0,1,5.357,0H58.929a5.359,5.359,0,0,1,5.357,5.357"/>
        </g>
</Icon>

  )
}

export default OnlinePayments;
