import React from "react";
import styled from "@emotion/styled";

const Icon = styled.svg`
  width: ${props => props.width ? props.width : '100%' };
  height: auto;
    .cls-1 {
        fill: ${props => props.fill ? props.fill : '#fff'};
      }
`;

const Jobs = (props) => {
  return (
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.431 50" {...props}>
      <g id="Group_34" data-name="Group 34">
        <path id="Path_41" data-name="Path 41" class="cls-1" d="M21.429,25a12.492,12.492,0,1,0-.016,0h.016M30,28.571h-.926a17.259,17.259,0,0,1-15.29,0h-.926A12.861,12.861,0,0,0,0,41.429v3.214A5.359,5.359,0,0,0,5.357,50H37.5a5.359,5.359,0,0,0,5.357-5.357V41.429A12.861,12.861,0,0,0,30,28.571M53.571,25A10.714,10.714,0,1,0,42.857,14.286,10.714,10.714,0,0,0,53.571,25m5.357,3.571H58.5a14.076,14.076,0,0,1-9.867,0h-.424A12.41,12.41,0,0,0,42,30.29a16.332,16.332,0,0,1,4.431,11.138v4.286c0,.245-.055.481-.067.714h19.71a5.359,5.359,0,0,0,5.357-5.357,12.492,12.492,0,0,0-12.484-12.5h-.016"/>
      </g>
</Icon>

  )
}

export default Jobs;
