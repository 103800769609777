import React from "react";
import styled from "@emotion/styled";

const Icon = styled.svg`
  width: ${props => props.width ? props.width : '100%' };
  height: auto;
    .cls-1 {
        fill: ${props => props.fill ? props.fill : '#fff'};
      }
`;

const PublicComment = (props) => {
  return (
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 49.9949" {...props}>
      <g id="Group_38" data-name="Group 38">
        <path id="Path_43" data-name="Path 43" class="cls-1" d="M43.747,0H6.25A6.255,6.255,0,0,0,0,6.25V34.373a6.255,6.255,0,0,0,6.25,6.25h9.374v8.2a1.174,1.174,0,0,0,1.865.947l12.2-9.149H43.747A6.256,6.256,0,0,0,50,34.373V6.25A6.256,6.256,0,0,0,43.747,0M21.034,30.33l-4.706.527a.993.993,0,0,1-1.095-1.093l.528-4.706,9.4-9.4,5.273,5.275ZM35.749,15.614l-3.1,3.1-5.273-5.273,3.1-3.1a1.974,1.974,0,0,1,2.79166-.00134l.00134.00134,2.48,2.48a1.974,1.974,0,0,1,.00135,2.79166L35.749,15.614"/>
      </g>
</Icon>
  )
}

export default PublicComment;
