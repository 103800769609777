import React from "react";
import styled from "@emotion/styled";

const Icon = styled.svg`
  width: ${props => props.width ? props.width : '100%' };
  height: auto;
    .cls-1 {
        fill: ${props => props.fill ? props.fill : '#fff'};
      }
`;

const Applications = (props) => {
  return (
<Icon id="Group_29" data-name="Group 29" xmlns="http://www.w3.org/2000/svg" width="37.5" height="50" viewBox="0 0 37.5 50" {...props}>
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_10" data-name="Rectangle 10" width="37.5" height="50" className="cls-1"/>
    </clipPath>
  </defs>
  <g id="Group_28" data-name="Group 28" clip-path="url(#clip-path)">
    <path id="Path_38" data-name="Path 38" d="M21.875,13.28V0H2.343A2.338,2.338,0,0,0,0,2.343V47.655A2.338,2.338,0,0,0,2.343,50H35.157A2.338,2.338,0,0,0,37.5,47.655V15.625H24.218a2.351,2.351,0,0,1-2.343-2.345M6.25,7.03a.78.78,0,0,1,.782-.78h7.812a.78.78,0,0,1,.782.78V8.593a.782.782,0,0,1-.782.782H7.032a.782.782,0,0,1-.782-.782Zm0,6.25a.78.78,0,0,1,.782-.78h7.812a.78.78,0,0,1,.782.78v1.563a.782.782,0,0,1-.782.782H7.032a.782.782,0,0,1-.782-.782ZM25.08,37.5h4.608a1.563,1.563,0,0,1,0,3.125H25.08a4.2,4.2,0,0,1-3.773-2.33,1.082,1.082,0,0,0-.993-.637,1.063,1.063,0,0,0-.978.6l-.75,1.5a1.56,1.56,0,0,1-1.4.863c-.038,0-.073,0-.112-.005a1.555,1.555,0,0,1-1.37-1.063l-1.643-4.928L13.027,37.74a4.216,4.216,0,0,1-4,2.885H7.813a1.563,1.563,0,1,1,0-3.125h1.21a1.1,1.1,0,0,0,1.038-.748l1.777-5.337a2.345,2.345,0,0,1,4.448,0l1.355,4.067a4.382,4.382,0,0,1,6.445,1.383A1.117,1.117,0,0,0,25.08,37.5ZM36.817,10.253,27.257.683A2.34,2.34,0,0,0,25.6,0H25V12.5H37.5v-.6a2.334,2.334,0,0,0-.683-1.65" className="cls-1"/>
  </g>
</Icon>
  )
}

export default Applications;
