import React from "react";
import styled from "@emotion/styled";

const Icon = styled.svg`
  width: ${props => props.width ? props.width : '100%' };
  height: auto;
    .cls-1 {
        fill: ${props => props.fill ? props.fill : '#fff'};
      }
`;

const Bids = (props) => {
  return (
<Icon id="Group_33" data-name="Group 33" xmlns="http://www.w3.org/2000/svg" width="37.5" height="50" viewBox="0 0 37.5 50" {...props}>
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_12" data-name="Rectangle 12" width="37.5" height="50" fill="#fff"/>
    </clipPath>
  </defs>
  <g id="Group_32" data-name="Group 32" clip-path="url(#clip-path)">
    <path id="Path_40" data-name="Path 40" d="M36.817,10.253,27.255.683A2.343,2.343,0,0,0,25.6,0H25V12.5H37.5v-.6a2.335,2.335,0,0,0-.683-1.652M21.873,13.282V0H2.343A2.338,2.338,0,0,0,0,2.343V47.657A2.338,2.338,0,0,0,2.343,50H35.157A2.338,2.338,0,0,0,37.5,47.657V15.625H24.218a2.351,2.351,0,0,1-2.345-2.343M6.25,7.032a.782.782,0,0,1,.782-.782h7.812a.78.78,0,0,1,.78.782V8.593a.78.78,0,0,1-.78.782H7.032a.782.782,0,0,1-.782-.782Zm0,7.812V13.282a.782.782,0,0,1,.782-.782h7.812a.78.78,0,0,1,.78.782v1.562a.78.78,0,0,1-.78.782H7.032a.782.782,0,0,1-.782-.782m14.063,25.77v2.355a.783.783,0,0,1-.783.782H17.968a.781.781,0,0,1-.78-.782V40.6a5.591,5.591,0,0,1-3.065-1.108.781.781,0,0,1-.055-1.185l1.147-1.1a.8.8,0,0,1,.99-.072,2.349,2.349,0,0,0,1.252.363H20.2a1.229,1.229,0,0,0,1.153-1.288,1.277,1.277,0,0,0-.858-1.243L16.1,33.65a4.417,4.417,0,0,1-3.083-4.237,4.347,4.347,0,0,1,4.165-4.4V22.657a.782.782,0,0,1,.782-.782H19.53a.78.78,0,0,1,.78.782v2.372a5.578,5.578,0,0,1,3.063,1.108.78.78,0,0,1,.057,1.185l-1.148,1.095a.8.8,0,0,1-.988.072,2.349,2.349,0,0,0-1.252-.363H17.3a1.229,1.229,0,0,0-1.153,1.288A1.275,1.275,0,0,0,17,30.657l4.395,1.318a4.42,4.42,0,0,1,3.085,4.237A4.35,4.35,0,0,1,20.313,40.613Z" fill="#fff"/>
  </g>
</Icon>
  )
}

export default Bids;
