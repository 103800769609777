import React from "react";
import styled from "@emotion/styled";

const Icon = styled.svg`
  width: ${props => props.width ? props.width : '100%' };
  height: auto;
    .cls-1 {
        fill: ${props => props.fill ? props.fill : '#fff'};
      }
`;

const Calendar = (props) => {
  return (
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.75 50" {...props}>
      <g id="Group_36" data-name="Group 36">
        <path id="Path_42" data-name="Path 42" class="cls-1" d="M0,45.312A4.69,4.69,0,0,0,4.688,50H39.063a4.689,4.689,0,0,0,4.687-4.688V18.75H0Zm31.25-19.14A1.174,1.174,0,0,1,32.422,25h3.9A1.176,1.176,0,0,1,37.5,26.172v3.907a1.176,1.176,0,0,1-1.173,1.172h-3.9a1.174,1.174,0,0,1-1.172-1.172Zm0,12.5A1.174,1.174,0,0,1,32.422,37.5h3.9A1.176,1.176,0,0,1,37.5,38.672v3.907a1.176,1.176,0,0,1-1.173,1.172h-3.9a1.174,1.174,0,0,1-1.172-1.172Zm-12.5-12.5A1.174,1.174,0,0,1,19.922,25h3.907A1.174,1.174,0,0,1,25,26.172v3.907a1.174,1.174,0,0,1-1.172,1.172H19.922a1.174,1.174,0,0,1-1.172-1.172Zm0,12.5A1.174,1.174,0,0,1,19.922,37.5h3.907A1.174,1.174,0,0,1,25,38.672v3.907a1.174,1.174,0,0,1-1.172,1.172H19.922a1.174,1.174,0,0,1-1.172-1.172Zm-12.5-12.5A1.175,1.175,0,0,1,7.423,25h3.9A1.175,1.175,0,0,1,12.5,26.172v3.907a1.175,1.175,0,0,1-1.173,1.172h-3.9A1.175,1.175,0,0,1,6.25,30.078h0Zm0,12.5A1.175,1.175,0,0,1,7.423,37.5h3.9A1.175,1.175,0,0,1,12.5,38.672v3.907a1.175,1.175,0,0,1-1.173,1.172h-3.9A1.175,1.175,0,0,1,6.25,42.578V38.672ZM39.063,6.25h-4.69V1.562A1.566,1.566,0,0,0,32.813,0H29.688a1.566,1.566,0,0,0-1.563,1.562V6.25h-12.5V1.562A1.567,1.567,0,0,0,14.063,0H10.938A1.566,1.566,0,0,0,9.375,1.562V6.25H4.688A4.69,4.69,0,0,0,0,10.938v4.687H43.75V10.938A4.689,4.689,0,0,0,39.063,6.25"/>
      </g>
</Icon>
  )
}

export default Calendar;
