import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Center,
    LinkOverlay,
    LinkBox
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import styled from '@emotion/styled';

import Applications from './Icons/Applications'
import Bids from './Icons/Bids'
import Calendar from './Icons/Calendar';
import Jobs from './Icons/Jobs';
import MeetingInfo from './Icons/MeetingInfo'
import OnlinePayments from './Icons/OnlinePayments';
import PublicComment from './Icons/PublicComment';

const CircleIcon = styled(LinkBox)`
    position: relative;
    display: block;
    margin: 2em 0;
    text-align: center;
    padding: 1rem;
    &:after {
        display: block;
        padding-bottom: 100%;
        width: 100%;
        height: 0;
        border-radius: 50%;
        background-color: #134053;
        content: "";
        box-sizing: inherit;
    }
    &:hover:after { 
        background-color: #1B5A74;
    }
    &:hover > div > div > div > h3 {
        color: #FCBA04;
    }
    &:hover > div > div > div > div > div > div > svg > g > path {
        fill: #FCBA04;
    }    
`;

const CircleInner = styled(Box)`
position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const CircleWrapper = styled(Box)`
display: table;
  width: 100%;
  height: 100%;
`;

const CircleContent = styled(Box)`
    display: table-cell;
  padding: 1em;
  vertical-align: middle;
`;

const SvgBox = styled(Box)`
    max-width: 2rem;
    margin-bottom: 0.5rem;
`;

const CircleTitle = styled(Heading)`
    > * {
        color: white;
    }
    > *:hover {
        color: #FCBA04;
    }
`;


const ToolBarCircles = (props) => {
    return (
        <Flex justifyContent="space-between" w="100%" flexWrap="wrap">
                <CircleIcon w={{base: '50%',  md: '25%', lg: '14%' }} role="button" >
                    <CircleInner>
                        <CircleWrapper>
                           <CircleContent>
                               <Box textAlign="center">
                                   <Center>
                                       <SvgBox style={{maxWidth: '2.5rem'}}>
                                            <MeetingInfo width="2.5rem" />
                                        </SvgBox>
                                    </Center>
                                </Box>
                                <CircleTitle as="h3" size="md" color="white" textAlign="center">
                                        <LinkOverlay as={GatsbyLink} to="/boards-commissions-committees" className='circleLink'>Meeting<br />Information</LinkOverlay>
                                </CircleTitle>
                           </CircleContent>
                        </CircleWrapper>
                    </CircleInner>
                </CircleIcon>
            <CircleIcon w={{base: '50%', md: '25%', lg: '14%' }} role="button">
                <CircleInner>
                    <CircleWrapper>
                       <CircleContent>
                       <Box textAlign="center">
                               <Center>
                                   <SvgBox>
                                       <Applications fill={props.fill}/>
                                       {/* <img src={ApplicationsForms} alt="Meeting Info"  /> */}
                                    </SvgBox>
                                </Center>
                            </Box>
                            <CircleTitle as="h3" size="md" color="white" textAlign="center">
                                <LinkOverlay as={GatsbyLink} to="/forms-applications" className='circleLink'>Applications<br />&amp; Forms</LinkOverlay>
                            </CircleTitle>
                       </CircleContent>
                    </CircleWrapper>
                </CircleInner>
            </CircleIcon>
            <CircleIcon w={{base: '50%', md: '25%', lg: '14%' }} role="button">
                <CircleInner>
                    <CircleWrapper>
                       <CircleContent>
                       <Box textAlign="center">
                               <Center>
                                   <SvgBox style={{maxWidth: "3rem"}}>
                                       <OnlinePayments width="3rem" />
                                    </SvgBox>
                                </Center>
                            </Box>
                            <CircleTitle as="h3" size="md" color="white" textAlign="center">
                                <LinkOverlay as={GatsbyLink} to="/online-payments" className='circleLink'>
                                    Online<br />Payments
                                </LinkOverlay>
                            </CircleTitle>
                       </CircleContent>
                    </CircleWrapper>
                </CircleInner>
            </CircleIcon>
            <CircleIcon w={{base: '50%', md: '25%', lg: '14%' }} role="button">
                <CircleInner>
                    <CircleWrapper>
                       <CircleContent>
                       <Box textAlign="center">
                               <Center>
                                   <SvgBox>
                                       <Bids />
                                    </SvgBox>
                                </Center>
                            </Box>
                            <CircleTitle as="h3" size="md" color="white" textAlign="center">
                                <LinkOverlay as={GatsbyLink} to="/bids-rfp" className='circleLink'>Bids<br />&amp; RFPs</LinkOverlay>
                            </CircleTitle>
                       </CircleContent>
                    </CircleWrapper>
                </CircleInner>
            </CircleIcon>
            <CircleIcon w={{base: '50%', md: '25%', lg: '14%' }} role="button">
                <CircleInner>
                    <CircleWrapper>
                       <CircleContent>
                            <Box textAlign="center">
                               <Center>
                                   <SvgBox style={{maxWidth: '3rem'}}>
                                        <Jobs width="3rem" />   
                                    </SvgBox>
                                </Center>
                            </Box>
                            <CircleTitle as="h3" size="md" color="white" textAlign="center">
                                <LinkOverlay as={GatsbyLink} to="/departments/human-resources/job-openings" className='circleLink'>Job<br />Openings</LinkOverlay>
                            </CircleTitle>
                       </CircleContent>
                    </CircleWrapper>
                </CircleInner>
            </CircleIcon>
            <CircleIcon w={{base: '50%', md: '25%', lg: '14%' }} role="button">
                <CircleInner>
                    <CircleWrapper>
                       <CircleContent>
                            <Box textAlign="center">
                               <Center>
                                   <SvgBox  style={{maxWidth: '2.5rem'}}>
                                       <Calendar width="2.5rem"/>
                                    </SvgBox>
                                </Center>
                            </Box>
                            <CircleTitle as="h3" size="md" color="white" textAlign="center">
                                <LinkOverlay as={GatsbyLink} to="/calendar" className='circleLink'>City<br />Calendar</LinkOverlay>
                            </CircleTitle>
                       </CircleContent>
                    </CircleWrapper>
                </CircleInner>
            </CircleIcon>
            <CircleIcon w={{base: '50%', md: '25%', lg: '14%' }} role="button">
                <CircleInner>
                    <CircleWrapper>
                       <CircleContent>
                            <Box textAlign="center">
                               <Center>
                                   <SvgBox style={{maxWidth: '2.5rem'}}>
                                       <PublicComment width="2.5rem" />
                                    </SvgBox>
                                </Center>
                            </Box>
                            <CircleTitle as="h3" size="md" color="white" textAlign="center">
                                <LinkOverlay as={GatsbyLink} to="/public-hearing" className='circleLink'>Public<br />Comment</LinkOverlay>
                            </CircleTitle>
                       </CircleContent>
                    </CircleWrapper>
                </CircleInner>
            </CircleIcon>
            
        </Flex>
    );
}

export default ToolBarCircles;